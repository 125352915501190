export var DEFAULT_MOCK_CATALOG = [
    {
        id: {
            treatmentCode: "12ff0.6e3.34f5a056.6e56e6d6",
            campaignId: "000001762"
        },
        name: "+1000 int. Old BO",
        type: "addon",
        productType: "NBA",
        description: "+1000 international",
        longDescription: "+1000 international",
        campaignDetail: {
            price: [
                {
                    priceRef: "campaign",
                    value: "0.00",
                    priceType: "recurring"
                },
                {
                    priceRef: "campaign",
                    value: "0.00",
                    priceType: "oneshot"
                },
                {
                    priceRef: "market",
                    value: "10.00",
                    priceType: "recurring"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "oneshot"
                }, 
            ],
            components: {
                retrieveSessionId: "8275004385361048485",
                campaignDescription: "test",
                billingOffer: [
                    {
                        id: "260198695",
                        price: [
                            {
                                value: "0",
                                priceType: "recurring"
                            }, 
                        ],
                        duration: "0"
                    }, 
                ]
            }
        },
        activationDate: "2024-05-08"
    },
    {
        id: {
            treatmentCode: "3f19.323.ffffffffc4b8e63c.a3c822c",
            campaignId: "000000802"
        },
        name: "Vodafone Gold",
        type: "change plan",
        productType: "NBA",
        description: "Minuti, SMS e Giga tutto illimitato sulla Rete Vodafone 5G ",
        longDescription: "Per te un'offerta chiara e semplice dove tutto ? incluso! Con Vodafone Gold hai minuti, SMS e Giga illimitati alla massima velocit? sulla Rete Vodafone 5G e 1000 minuti internazionali verso i paesi dell'Unione Europea, USA e tanti altri. Da oggi non pagherai pi? servizi standard come il costo del piano telefonico, il 414 per conoscere il tuo credito residuo, la ricevuta di ritorno degli SMS, il servizio chiamami per sapere quando sei disponibile sul tuo cellulare, la segreteria telefonica, la continuit? di servizio che ti permette di utilizzare il tuo cellulare anche a credito negativo e Vodafone Exclusive, perch? saranno inclusi nella tua offerta. Contributo di attivazione 1€.",
        campaignDetail: {
            featureDescription: [
                {
                    name: "GIGA MINUTI E SMS",
                    value: "Illimitati"
                },
                {
                    name: "MINUTI VERSO EXTRA UE",
                    value: "100"
                },
                {
                    name: "Minuti UE, USA, altri paesi",
                    value: "1000"
                },
                {
                    name: "Minuti Vodafone Albania",
                    value: "1000"
                },
                {
                    name: "Minuti Vodafone Romania Mobile",
                    value: "Illimitati"
                }, 
            ],
            price: [
                {
                    priceRef: "campaign",
                    value: "23.90",
                    priceType: "recurring"
                },
                {
                    priceRef: "campaign",
                    value: "1.00",
                    priceType: "oneshot"
                },
                {
                    priceRef: "market",
                    value: "4.90",
                    priceType: "recurring"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "oneshot"
                }, 
            ],
            components: {
                retrieveSessionId: "8275004385361048485",
                campaignDescription: "test",
                productOfferId: "4269585",
                billingOffer: [
                    {
                        id: "4266485",
                        price: [
                            {
                                value: "23.9",
                                priceType: "recurring"
                            }, 
                        ]
                    },
                    {
                        id: "3208098",
                        price: [
                            {
                                value: "1",
                                priceType: "oneshot"
                            }, 
                        ]
                    },
                    {
                        id: "8392283"
                    }, 
                ]
            }
        },
        cmsCategory: "nba",
        cmsCustomization: {
            activationCost: {},
            renewalCost: {},
            card: {
                shortDescription: 'Minuti, SMS e Giga Illimitati (secondo <u class="FUP">uso corretto e lecito</u>) alla massima velocit\xe0 in 5G con tutto incluso ',
                primaryCTA: {},
                defaultCTA: {},
                commercialName: "Giga illimitati +",
                hero: {
                    type: "image",
                    path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/febbraio_2024/Card_catalog_MVA12_MM4m__Febbraio_prezzo%20bloccato%20NO%205G.jpg"
                }
            },
            detail: {
                header: {
                    shortDescription: "Tutto illimitato e massima velocit\xe0",
                    featureList: [
                        "Giga 5G illimitati",
                        "Minuti e SMS illimitati"
                    ]
                },
                offer: {
                    description: "Minuti, SMS e Giga illimitati alla massima velocit\xe0 in 5G sulla migliore rete Vodafone con tutto incluso.",
                    featureList: [
                        {
                            text: 'Giga in 5G alla massima velocit\xe0, minuti e SMS illimitati (secondo uso corretto e lecito come indicato nelle FAQ "Giga Illimitati" e "Minuti e/o SMS illimitati" in basso)',
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/5G_Icona.png",
                            cta: {}
                        },
                        {
                            text: "1000 minuti internazionali verso i paesi dell'Unione Europea, USA e tanti altri paesi",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/UE.png",
                            cta: {}
                        },
                        {
                            text: "100 minuti verso i paesi Extra Unione Europea",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/aereo.png",
                            cta: {}
                        },
                        {
                            text: "Segreteria Telefonica, servizi Chiamami e Recall e chiamate al 414",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/Vector.png",
                            cta: {}
                        }, 
                    ],
                    media: {
                        type: "image"
                    }
                },
                faqs: [
                    {
                        title: "Dettagli e costi",
                        text: "Con Vodafone Gold hai minuti, SMS e Giga illimitati sulla Rete Vodafone 5G. In pi\xf9 hai:<br />\r\n<br />\r\nutilizzo dello smartphone in modalit\xe0 hotspot<br />\r\nSegreteria Telefonica, servizi Chiamami e Recall e chiamate al 414 per verificare il credito residuo<br />\r\nminuti illimitati verso Vodafone Romania Mobile<br />\r\n1000 minuti verso Vodafone Albania<br />\r\n1000 minuti internazionali verso i paesi dell'Unione Europea, USA e tanti altri: Argentina, Austria, Bangladesh, Belgio, Bulgaria, Canada, Cina, Colombia, Repubblica Ceca, Danimarca, Repubblica Dominicana, Finlandia, Francia, Germania, Grecia, Ungheria, India, Irlanda, Malta, Messico, Paesi Bassi, Norvegia, Pakistan, Peru, Polonia, Portogallo, Romania, Slovenia, Spagna, Svezia, Svizzera, Regno Unito, USA e numeri fissi in Australia, Brasile, Israele e Marocco+D1\r\n100 minuti verso i paesi Extra Unione Europea e altri: Bolivia, Cile, Ecuador, Egitto, Filippine, Sri Lanka, Nigeria, Turchia, Venezuela e numeri fissi in Brasile"
                    },
                    {
                        title: "Giga Illimitati",
                        text: 'Giga illimitati alla massima velocit\xe0 in 5G e l\'utilizzo dello smartphone in modalit\xe0 hotspot. <br />\r\nI Giga illimitati sono soggetti alle condizioni di uso corretto e lecito, presenti <a href="https://www.vodafone.it/privati/area-supporto/contratti-aggiornamenti/condizioni-generali-reti-servizi/condizioni-generali-contratto-rete-mobile.html?app=true">nell\'art.2.5 delle Condizioni generali di Contratto</a>. <br />\r\nIn particolare l\'uso del servizio si considera lecito e corretto in presenza di volumi di traffico dati mensili inferiore a cinque volte il consumo medio di Clienti con offerte omogenee o analoghe (Informazioni sul consumo medio sono disponibili nella sezione Supporto/Domande frequenti/<a href="https://www.vodafone.it/privati/area-supporto/sim-piano-mobile/gestione-offerte-piano/limiti-rispettare-utilizzo-mia-sim.html?app=true">Cosa si intende per uso corretto e lecito</a>?)'
                    },
                    {
                        title: "Minuti e/o SMS Illimitati",
                        text: "L'uso della SIM deve essere personale secondo i principi di buona fede e correttezza come meglio dettagliati <a href=\"https://www.vodafone.it/privati/area-supporto/contratti-aggiornamenti/condizioni-generali-reti-servizi/condizioni-generali-contratto-rete-mobile.html?app=true\">nell'art.2.5 delle Condizioni Generali di Contratto</a> su vodafone.it."
                    },
                    {
                        title: "Estero",
                        text: 'L\'offerta pu\xf2 essere utilizzata anche nei Paesi dell\'Unione Europea, nel rispetto delle condizioni di uso corretto e lecito. Sono escluse le chiamate internazionali. Per maggiori informazioni e per scoprire quanti Giga puoi utilizzare in Unione Europea vai su <a href="https://voda.it/euroaming?app=true">voda.it/euroaming</a> oppure su <a href="https://voda.it/estero?app=true">voda.it/estero.</a>'
                    },
                    {
                        title: "Internet",
                        text: "Puoi utilizzare i tuoi Giga per navigare su APN mobile.vodafone.it, iphone.vodafone.it, wap.omnitel.it e web.omnitel.it"
                    },
                    {
                        title: "Velocit\xe0 di navigazione",
                        text: "Per navigare in 5G devi avere un dispositivo e un'offerta abilitati, una SIM da almeno 128K ed essere sotto copertura 5G. Per maggiori informazioni e per conoscere l'elenco aggiornato dei dispositivi abilitati vai sulla pagina dedicata alla rete 5G."
                    },
                    {
                        title: "Disattivazione",
                        text: "Puoi disattivare in qualsiasi momento dall'app My Vodafone cliccando su Gestisci > Offerte Attive o sul Fai da Te del sito www.vodafone.itv"
                    }, 
                ]
            }
        },
        activationDate: "2024-05-08"
    },
    {
        id: {
            treatmentCode: "23438.457.fffffffffbb92e05.ffffffffe1d5e9a5",
            campaignId: "000001110"
        },
        name: "SANITY - VODAFONE BRONZE + DP&S",
        type: "change plan",
        productType: "NBA",
        description: "Minuti, SMS illimitati e 50 Giga sulla Rete Vodafone ",
        longDescription: "Per te un'offerta chiara e semplice dove tutto ? incluso! Con Vodafone Bronze a 9.90€ al mese hai 50 Giga alla massima velocit? della Rete Vodafone, minuti illimitati, SMS illimitati. Da oggi non pagherai pi? servizi standard come il costo del piano telefonico, il 414 per conoscere il tuo credito residuo , la ricevuta di ritorno ai messaggi di testo, il servizio chiamami, utile a per sapere quando sei disponibile sul tuo cellulare, le chiamate alla segreteria telefonica, la continuit? del servizio che ti permette di utilizzare il tuo cellulare anche a credito negativo e Vodafone Exclusive, perch? saranno inclusi nella tua offerta. Contributo iniziale di attivazione 1€.",
        campaignDetail: {
            featureDescription: [
                {
                    name: "Internet",
                    value: "50 Giga"
                },
                {
                    name: "MINUTI e SMS",
                    value: "Illimitati"
                }, 
            ],
            price: [
                {
                    priceRef: "campaign",
                    value: "9.90",
                    priceType: "recurring"
                },
                {
                    priceRef: "campaign",
                    value: "0.00",
                    priceType: "oneshot"
                },
                {
                    priceRef: "market",
                    value: "4.90",
                    priceType: "recurring"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "oneshot"
                }, 
            ],
            components: {
                retrieveSessionId: "8275004385361048485",
                campaignDescription: "test",
                productOfferId: "4269285",
                billingOffer: [
                    {
                        id: "4266285",
                        price: [
                            {
                                value: "9.9",
                                priceType: "recurring"
                            }, 
                        ]
                    }, 
                ]
            }
        },
        activationDate: "2024-05-08"
    },
    {
        id: {
            treatmentCode: "326c7.b2c.ffffffff82a9dff2.ffffffffa6050802",
            campaignId: "000002859"
        },
        name: "Vodafone Casa Wireless 4G Outdoor 23,90€/m",
        type: "fixed change plan",
        productType: "NBA",
        description: "Per te che sei gia' cliente Vodafone, internet senza limiti su tecnologia FWA Outdoor con il meglio del 4G  a 23,90€ al mese di cui 5€ per 24 mesi gi? inclusi nel canone + 39.90€ di anticipo iniziale",
        longDescription: "Scopri la massima affidabilita' e il Wi-Fi forte e chiaro della Rete FWA 4G per avere la migliore esperienza di connessione. Internet senza limiti su tecnologia FWA Outdoor con il meglio del 4G, modem Wi-Fi Optimizer e antenna FWA esterna per un'esperienza di connessione superiore, chiamate illimitate verso numeri fissi e mobili nazionali. In piu' con Infinito Insieme hai Giga illimitati alla massima velocita' della Rete Vodafone sul tuo numero mobile!  Per te che sei gia' cliente mobile puoi avere Vodafone Casa Wireless + 4G al prezzo scontato di 23,90€ al mese di cui 5€ per 24 mesi gi? inclusi nel canone + 39.90€ di anticipo iniziale. Lo sconto a 23,90€ al mese e' valido solo se mantieni attiva la tua offerta mobile. Se disattivi la componente mobile pagherai 29,90€ euro al mese.",
        bundlePO: "6391483",
        campaignDetail: {
            bolloDescription: "Sconto 6€ in cassa",
            components: {
                retrieveSessionId: "8275004385361048485"
            }
        },
        cmsHighlighted: true,
        cmsCategory: "nba",
        cmsCustomization: {
            activationCost: {
                price: " ",
                frequency: " "
            },
            renewalCost: {
                price: "23,90 €",
                frequency: "al mese"
            },
            card: {
                shortDescription: "Scegli il Wi-Fi di casa forte e chiaro.",
                primaryCTA: {
                    label: "Ti chiamiamo gratis",
                    actionURL: "ancora_FWA_scroll_card"
                },
                defaultCTA: {
                    label: "Scopri di pi\xf9",
                    actionURL: "ancora_FWA_scroll_card"
                },
                commercialName: "FWA 4G",
                hero: {
                    type: "image",
                    path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/maggio_2024/Card_catalog_MVA12_Lockin_FIBRA_BuonoAmazon_100_scadenza8maggio.jpg"
                }
            },
            detail: {
                header: {
                    featureList: []
                },
                offer: {}
            }
        },
        activationDate: "2024-05-08"
    },
    {
        id: {
            treatmentCode: "326c3.b28.ffffffffee8beb6b.ffffffff9be24bab",
            campaignId: "000002855"
        },
        name: "Vodafone Casa Wireless 5G Outdoor 23,90€/m",
        type: "fixed change plan",
        productType: "NBA",
        description: "Per te che sei gia' cliente Vodafone, internet senza limiti su tecnologia FWA Outdoor con il meglio del 5G  a 23,90€ al mese di cui 5€ per 24 mesi gi? inclusi nel canone + 39.90€ di anticipo iniziale",
        longDescription: "Scopri la massima affidabilita' e il Wi-Fi forte e chiaro della Rete FWA 5G per avere la migliore esperienza di connessione. Internet senza limiti su tecnologia FWA Outdoor con il meglio del 5G, modem Wi-Fi Optimizer e antenna FWA esterna per un'esperienza di connessione superiore, chiamate illimitate verso numeri fissi e mobili nazionali. In piu' con Infinito Insieme hai Giga illimitati alla massima velocita' della Rete Vodafone sul tuo numero mobile! Per te che sei gia' cliente mobile puoi avere Vodafone Casa Wireless + 5G al prezzo scontato di 23,90€ al mese di cui 5€ per 24 mesi gi? inclusi nel canone + 39.90€ di anticipo iniziale. Lo sconto a 23,90€ al mese e' valido solo se mantieni attiva la tua offerta mobile. Se disattivi la componente mobile pagherai 29,90€ euro al mese.",
        bundlePO: "8671883",
        campaignDetail: {
            bolloDescription: "Sconto 6€ in cassa",
            components: {
                retrieveSessionId: "8275004385361048485"
            }
        },
        cmsHighlighted: true,
        cmsCategory: "nba",
        cmsCustomization: {
            activationCost: {
                price: " ",
                frequency: " "
            },
            renewalCost: {
                price: "23,90 €",
                frequency: "al mese"
            },
            card: {
                shortDescription: "Scegli il Wi-Fi di casa forte e chiaro, con tutta la potenza del 5G.",
                primaryCTA: {
                    label: "Ti chiamiamo gratis",
                    actionURL: "ancora_FWA_scroll_card"
                },
                defaultCTA: {
                    label: "Scopri di pi\xf9",
                    actionURL: "ancora_FWA_scroll_card"
                },
                commercialName: "FWA 5G",
                hero: {
                    type: "image",
                    path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/maggio_2024/Card_catalog_MVA12_Lockin_FIBRA_BuonoAmazon_100_scadenza8maggio.jpg"
                }
            },
            detail: {
                header: {
                    featureList: []
                },
                offer: {}
            }
        },
        activationDate: "2024-05-08"
    },
    {
        id: {
            treatmentCode: "1bf78.83f.ffffffff8ee38088.4d4b0af8",
            campaignId: "000002110"
        },
        name: "5G Priority Access",
        type: "addon",
        productType: "NBA",
        description: "Con Vodafone hai la potenza del 5G alla massima velocita' e l'accesso prioritario alla Rete per navigare al meglio, sempre.",
        campaignDetail: {
            price: [
                {
                    priceRef: "campaign",
                    value: "0.49",
                    priceType: "recurring"
                },
                {
                    priceRef: "campaign",
                    value: "0.00",
                    priceType: "oneshot"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "recurring"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "oneshot"
                }, 
            ],
            components: {
                campaignDescription: "test",
                retrieveSessionId: "8275004385361048485",
                billingOffer: [
                    {
                        id: "4763383",
                        price: [
                            {
                                value: "0.49",
                                priceType: "recurring"
                            }, 
                        ]
                    }, 
                ]
            }
        },
        cmsCategory: "prodotti-da-nascondere",
        cmsCustomization: {
            activationCost: {
                price: " ",
                frequency: " "
            },
            renewalCost: {},
            card: {
                shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                primaryCTA: {},
                defaultCTA: {},
                commercialName: "5G - Naviga al massimo",
                hero: {
                    type: "image",
                    path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/02_gennaio_23/Card_catalog_MVA12_5G-Priority-Access_v2.jpg"
                }
            },
            detail: {
                header: {
                    shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                    featureList: []
                },
                offer: {
                    description: "Con 5G Priority Access hai tutta la potenza del 5G alla massima velocit\xe0 di navigazione e l'Accesso Prioritario alla Rete mobile 4G in caso di congestione di rete.",
                    featureList: [
                        {
                            text: "Qualit\xe0 di Rete anche nei luoghi affollati",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club1.png",
                            cta: {}
                        },
                        {
                            text: "Connessione stabile e veloce con il 5G: 5 volte pi\xf9 veloce rispetto alla tecnologia 4G",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/5G_Icona.png",
                            cta: {}
                        },
                        {
                            text: "Upload e download veloci",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club2.png",
                            cta: {}
                        },
                        {
                            text: "Videocall fluide",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/video@2x.png",
                            cta: {}
                        },
                        {
                            text: "Streaming ad alta definizione",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/tvhd@2x.png",
                            cta: {}
                        },
                        {
                            text: "Giochi online senza interruzioni",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/game_console@2x.png",
                            cta: {}
                        },
                        {
                            text: "Dispositivi smart sempre connessi",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/smart_home@2x.png",
                            cta: {}
                        }, 
                    ]
                }
            }
        },
        activationDate: "2024-05-08"
    },
    {
        id: {
            treatmentCode: "b706.63d.ffffffffef2edb25.33b447c5",
            campaignId: "000001596"
        },
        name: "5G Priority Access",
        type: "addon",
        productType: "NBA",
        description: "Con Vodafone hai la potenza del 5G alla massima velocita' e l'accesso prioritario alla Rete per navigare al meglio, sempre.",
        longDescription: "Con 5G Priority Access hai tutta la potenza del 5G alla massima velocit? di navigazione e l’Accesso Prioritario alla Rete mobile 4G in caso di congestione di rete. In caso di assenza di segnale 5G e di connessione dati alla rete 4G, avrai una facilitazione nell’accesso ad internet in situazioni di alto traffico sulla rete 4G. Infatti ? possibile che si verifichino casi di congestione della rete quando il carico delle risorse radio e degli elementi di rete ? particolarmente elevato. Con l’Accesso Prioritario, sono, di norma, assicurate migliori prestazioni, attribuendo maggiore priorit? al traffico dati effettuato sulla rete 4G. L’opzione ? disponibile solo per te al prezzo speciale di 2,99€ al mese e garantisce un accesso alle risorse capacitive condivise circa 4 volte maggiore.",
        campaignDetail: {
            price: [
                {
                    priceRef: "campaign",
                    value: "2.99",
                    priceType: "recurring"
                },
                {
                    priceRef: "campaign",
                    value: "0.00",
                    priceType: "oneshot"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "recurring"
                },
                {
                    priceRef: "market",
                    value: "0.00",
                    priceType: "oneshot"
                }, 
            ],
            components: {
                retrieveSessionId: "8275004385361048485",
                campaignDescription: "test",
                billingOffer: [
                    {
                        id: "4763383",
                        price: [
                            {
                                value: "2.99",
                                priceType: "recurring"
                            }, 
                        ]
                    }, 
                ]
            }
        },
        cmsCategory: "nba",
        cmsCustomization: {
            activationCost: {
                price: " ",
                frequency: " "
            },
            renewalCost: {},
            card: {
                shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                primaryCTA: {},
                defaultCTA: {},
                commercialName: "5G - Naviga al massimo",
                hero: {
                    type: "image",
                    path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/02_gennaio_23/Card_catalog_MVA12_5G-Priority-Access_v2.jpg"
                }
            },
            detail: {
                header: {
                    shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                    featureList: []
                },
                offer: {
                    description: "Con 5G Priority Access hai tutta la potenza del 5G alla massima velocit\xe0 di navigazione e l'Accesso Prioritario alla Rete mobile 4G in caso di congestione di rete.",
                    featureList: [
                        {
                            text: "Qualit\xe0 di Rete anche nei luoghi affollati",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club1.png",
                            cta: {}
                        },
                        {
                            text: "Connessione stabile e veloce con il 5G: 5 volte pi\xf9 veloce rispetto alla tecnologia 4G",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/5G_Icona.png",
                            cta: {}
                        },
                        {
                            text: "Upload e download veloci",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club2.png",
                            cta: {}
                        },
                        {
                            text: "Videocall fluide",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/video@2x.png",
                            cta: {}
                        },
                        {
                            text: "Streaming ad alta definizione",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/tvhd@2x.png",
                            cta: {}
                        },
                        {
                            text: "Giochi online senza interruzioni",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/game_console@2x.png",
                            cta: {}
                        },
                        {
                            text: "Dispositivi smart sempre connessi",
                            icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/smart_home@2x.png",
                            cta: {}
                        }, 
                    ]
                }
            }
        },
        activationDate: "2024-05-08"
    }, 
];
export var DEFAULT_MOCK_CATALOG_ADDON = {
    mop: "CR",
    sections: [
        {
            item: "3495256941",
            itemType: "sim",
            products: [
                {
                    id: {
                        productOfferingId: "257464495",
                        customerId: "700015038"
                    },
                    name: "5G Start",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    note: "Con 5G Start puoi raggiungere il massimo della performance della Rete Vodafone 5G",
                    category: "OnTopOfYourPlan",
                    isCampaign: false,
                    finalPrice: {
                        value: "5.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "2874998",
                        customerId: "700015038"
                    },
                    name: "Vodafone Club",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "OnTopOfYourPlan",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.99"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "5471883",
                        customerId: "700015038"
                    },
                    name: "Vodafone Club +",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "OnTopOfYourPlan",
                    isCampaign: false,
                    finalPrice: {
                        value: "14.99"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "2118487",
                        customerId: "700015038"
                    },
                    name: "New My Country",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "ConfigureYourSIM",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "2023387",
                        customerId: "700015038"
                    },
                    name: "Travel Europa",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "ConfigureYourSIM",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.99"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "2023287",
                        customerId: "700015038"
                    },
                    name: "Travel Mondo",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "ConfigureYourSIM",
                    isCampaign: false,
                    finalPrice: {
                        value: "0.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "2118687",
                        customerId: "700015038"
                    },
                    name: "+100 International",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "OnTopOfYourPlan",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "259530095",
                        customerId: "700015038"
                    },
                    name: "Rete Sicura 2.0",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "OnTopOfYourPlan",
                    isCampaign: false,
                    finalPrice: {
                        value: "1.99"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "5340883",
                        customerId: "700015038"
                    },
                    name: "Donazione 1 euro",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "AdditionalServices",
                    isCampaign: false,
                    finalPrice: {
                        value: "1.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "5311383",
                        customerId: "700015038"
                    },
                    name: "Donazione 2 euro",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "AdditionalServices",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "5311483",
                        customerId: "700015038"
                    },
                    name: "Donazione 5 euro",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "AdditionalServices",
                    isCampaign: false,
                    finalPrice: {
                        value: "5.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "6682683",
                        customerId: "700015038"
                    },
                    name: "Entertainment Pass",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "AdditionalServices",
                    isCampaign: false,
                    finalPrice: {
                        value: "6.99"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "1942287",
                        customerId: "700015038"
                    },
                    name: "Vodafone Plus con SMS",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    note: "Da pagare con credito residuo",
                    category: "ConfigureYourSIM",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.00"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "5481285",
                        customerId: "700015038"
                    },
                    name: "Rete Sicura 2.0",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "AddOn",
                    isCampaign: false,
                    finalPrice: {
                        value: "3.99"
                    },
                    activationDate: "2023-10-04"
                },
                {
                    id: {
                        productOfferingId: "8399683",
                        customerId: "700015038"
                    },
                    name: "Vodafone OneNumber",
                    type: "AssignedBillingOfferDisplay",
                    productType: "ADDON",
                    category: "AdditionalServices",
                    isCampaign: false,
                    finalPrice: {
                        value: "2.99"
                    },
                    activationDate: "2023-10-04"
                }, 
            ]
        }, 
    ]
};
export var DEFAULT_MOCK_CATALOG_NBA_ADDON = {
    mop: "CR",
    sections: [
        {
            item: "3444013440",
            itemType: "sim",
            products: [
                {
                    id: {
                        treatmentCode: "12ff0.6e3.34f5a056.6e56e6d6",
                        campaignId: "000001762"
                    },
                    name: "+1000 int. Old BO",
                    type: "addon",
                    productType: "NBA",
                    description: "+1000 international",
                    longDescription: "+1000 international",
                    campaignDetail: {
                        price: [
                            {
                                priceRef: "campaign",
                                value: "0.00",
                                priceType: "recurring"
                            },
                            {
                                priceRef: "campaign",
                                value: "0.00",
                                priceType: "oneshot"
                            },
                            {
                                priceRef: "market",
                                value: "10.00",
                                priceType: "recurring"
                            },
                            {
                                priceRef: "market",
                                value: "0.00",
                                priceType: "oneshot"
                            }, 
                        ],
                        components: {
                            retrieveSessionId: "8275004385361048485",
                            campaignDescription: "test",
                            billingOffer: [
                                {
                                    id: "260198695",
                                    price: [
                                        {
                                            value: "0",
                                            priceType: "recurring"
                                        }, 
                                    ],
                                    duration: "0"
                                }, 
                            ]
                        }
                    },
                    activationDate: "2024-05-08"
                },
                {
                    id: {
                        treatmentCode: "1bf78.83f.ffffffff8ee38088.4d4b0af8",
                        campaignId: "000002110"
                    },
                    name: "Vodafone Club",
                    type: "addon",
                    productType: "NBA",
                    description: "Con Vodafone Club hai la potenza del 5G alla massima velocita' e l'accesso prioritario alla Rete per navigare al meglio, sempre.",
                    campaignDetail: {
                        price: [
                            {
                                priceRef: "campaign",
                                value: "0.49",
                                priceType: "recurring"
                            },
                            {
                                priceRef: "campaign",
                                value: "0.00",
                                priceType: "oneshot"
                            },
                            {
                                priceRef: "market",
                                value: "0.00",
                                priceType: "recurring"
                            },
                            {
                                priceRef: "market",
                                value: "0.00",
                                priceType: "oneshot"
                            }, 
                        ],
                        components: {
                            campaignDescription: "test",
                            retrieveSessionId: "8275004385361048485",
                            billingOffer: [
                                {
                                    id: "257464495",
                                    price: [
                                        {
                                            priceRef: "campaign",
                                            value: "28.99",
                                            priceType: "recurring"
                                        },
                                        {
                                            priceRef: "campaign",
                                            value: "0.00",
                                            priceType: "oneshot"
                                        },
                                        {
                                            priceRef: "market",
                                            value: "2.99",
                                            priceType: "recurring"
                                        },
                                        {
                                            priceRef: "market",
                                            value: "0.00",
                                            priceType: "oneshot"
                                        }, 
                                    ]
                                },
                                {
                                    id: "8399683",
                                    price: [
                                        {
                                            priceRef: "campaign",
                                            value: "28.99",
                                            priceType: "recurring"
                                        },
                                        {
                                            priceRef: "campaign",
                                            value: "0.00",
                                            priceType: "oneshot"
                                        },
                                        {
                                            priceRef: "market",
                                            value: "2.99",
                                            priceType: "recurring"
                                        },
                                        {
                                            priceRef: "market",
                                            value: "0.00",
                                            priceType: "oneshot"
                                        }, 
                                    ]
                                }, 
                            ]
                        }
                    },
                    cmsCategory: "prodotti-da-nascondere",
                    cmsCustomization: {
                        activationCost: {
                            price: " ",
                            frequency: " "
                        },
                        renewalCost: {},
                        card: {
                            shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                            primaryCTA: {},
                            defaultCTA: {},
                            commercialName: "5G - Naviga al massimo",
                            hero: {
                                type: "image",
                                path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/02_gennaio_23/Card_catalog_MVA12_5G-Priority-Access_v2.jpg"
                            }
                        },
                        detail: {
                            header: {
                                shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                                featureList: []
                            },
                            offer: {
                                description: "Con 5G Priority Access hai tutta la potenza del 5G alla massima velocit\xe0 di navigazione e l'Accesso Prioritario alla Rete mobile 4G in caso di congestione di rete.",
                                featureList: [
                                    {
                                        text: "Qualit\xe0 di Rete anche nei luoghi affollati",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club1.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Connessione stabile e veloce con il 5G: 5 volte pi\xf9 veloce rispetto alla tecnologia 4G",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/5G_Icona.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Upload e download veloci",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club2.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Videocall fluide",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/video@2x.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Streaming ad alta definizione",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/tvhd@2x.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Giochi online senza interruzioni",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/game_console@2x.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Dispositivi smart sempre connessi",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/smart_home@2x.png",
                                        cta: {}
                                    }, 
                                ]
                            }
                        }
                    },
                    activationDate: "2024-05-08"
                },
                {
                    id: {
                        treatmentCode: "b706.63d.ffffffffef2edb25.33b447c5",
                        campaignId: "000001596"
                    },
                    name: "5G Priority Access",
                    type: "addon",
                    productType: "NBA",
                    description: "Con Vodafone hai la potenza del 5G alla massima velocita' e l'accesso prioritario alla Rete per navigare al meglio, sempre.",
                    longDescription: "Con 5G Priority Access hai tutta la potenza del 5G alla massima velocit? di navigazione e l’Accesso Prioritario alla Rete mobile 4G in caso di congestione di rete. In caso di assenza di segnale 5G e di connessione dati alla rete 4G, avrai una facilitazione nell’accesso ad internet in situazioni di alto traffico sulla rete 4G. Infatti ? possibile che si verifichino casi di congestione della rete quando il carico delle risorse radio e degli elementi di rete ? particolarmente elevato. Con l’Accesso Prioritario, sono, di norma, assicurate migliori prestazioni, attribuendo maggiore priorit? al traffico dati effettuato sulla rete 4G. L’opzione ? disponibile solo per te al prezzo speciale di 2,99€ al mese e garantisce un accesso alle risorse capacitive condivise circa 4 volte maggiore.",
                    campaignDetail: {
                        price: [
                            {
                                priceRef: "campaign",
                                value: "2.99",
                                priceType: "recurring"
                            },
                            {
                                priceRef: "campaign",
                                value: "0.00",
                                priceType: "oneshot"
                            },
                            {
                                priceRef: "market",
                                value: "0.00",
                                priceType: "recurring"
                            },
                            {
                                priceRef: "market",
                                value: "0.00",
                                priceType: "oneshot"
                            }, 
                        ],
                        components: {
                            retrieveSessionId: "8275004385361048485",
                            campaignDescription: "test",
                            billingOffer: [
                                {
                                    id: "4763383",
                                    price: [
                                        {
                                            value: "2.99",
                                            priceType: "recurring"
                                        }, 
                                    ]
                                }, 
                            ]
                        }
                    },
                    cmsCategory: "nba",
                    cmsCustomization: {
                        activationCost: {
                            price: " ",
                            frequency: " "
                        },
                        renewalCost: {},
                        card: {
                            shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                            primaryCTA: {},
                            defaultCTA: {},
                            commercialName: "5G - Naviga al massimo",
                            hero: {
                                type: "image",
                                path: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/02_gennaio_23/Card_catalog_MVA12_5G-Priority-Access_v2.jpg"
                            }
                        },
                        detail: {
                            header: {
                                shortDescription: "Connessione super veloce e tempi di risposta immediati. Con il nostro 5G non serve aspettare.",
                                featureList: []
                            },
                            offer: {
                                description: "Con 5G Priority Access hai tutta la potenza del 5G alla massima velocit\xe0 di navigazione e l'Accesso Prioritario alla Rete mobile 4G in caso di congestione di rete.",
                                featureList: [
                                    {
                                        text: "Qualit\xe0 di Rete anche nei luoghi affollati",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club1.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Connessione stabile e veloce con il 5G: 5 volte pi\xf9 veloce rispetto alla tecnologia 4G",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/5G_Icona.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Upload e download veloci",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/VF_club2.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Videocall fluide",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/video@2x.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Streaming ad alta definizione",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/tvhd@2x.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Giochi online senza interruzioni",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/game_console@2x.png",
                                        cta: {}
                                    },
                                    {
                                        text: "Dispositivi smart sempre connessi",
                                        icon: "https://cdn.mvacloud.vodafone.it/content/dam/myvf/mva10/08_next_catalogo/icone/smart_home@2x.png",
                                        cta: {}
                                    }, 
                                ]
                            }
                        }
                    },
                    activationDate: "2024-05-08"
                }, 
            ]
        }, 
    ]
};
