import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .check-size {\n    height: 12px;\n    width: 17px;\n    align-self: center;\n    margin-right: 16px;\n    margin-top: -7px;\n  }\n\n  button {\n    padding: 0;\n  }\n\n  display: flex;\n  margin-bottom: 20px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-style: normal;\n  margin: 32px 20px 41px;\n\n  @media (min-width: ",
        ") {\n    margin: 57px 37px 33px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 60px 55px 52px;\n  }\n\n  button {\n    background: transparent;\n    border: none;\n    text-align: start;\n    font-family: ",
        ";\n    font-weight: 700;\n    text-decoration: underline;\n    text-underline-position: from-font;\n    ",
        "\n    cursor: pointer;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    &,\n    &:active,\n    &:visited {\n      color: ",
        ";\n    }\n\n    &:hover {\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  margin-left: -3px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 22px;\n  margin-right: 10px;\n\n  @media (min-width: ",
        ") and (max-width: ",
        ") {\n    margin-top: 2px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  input {\n    position: absolute;\n    margin: 0 auto;\n    opacity: 0;\n    cursor: pointer;\n    height: 20px;\n    width: 20px;\n    z-index: 3;\n  }\n\n  .checkmark::after {\n    left: 7px;\n    top: 3px;\n    width: 5px;\n    height: 10px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    content: '';\n    position: absolute;\n    display: none;\n  }\n\n  .checkmark {\n    position: absolute;\n    height: 20px;\n    width: 20px;\n    border-radius: 3px;\n    border: 1px solid ",
        ";\n  }\n\n  &:hover input ~ .checkmark {\n    border: 1px solid ",
        ";\n  }\n\n  input:checked ~ .checkmark {\n    background-color: ",
        ";\n    border: none;\n  }\n\n  input:checked ~ .checkmark::after {\n    display: block;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  text-align: start;\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  width: 256px;\n\n  @media (min-width: ",
        ") {\n    width: 334px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    width: 374px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  height: 1px;\n  background: ",
        ";\n  margin: 32px 0;\n\n  @media (min-width: ",
        ") {\n    margin: 40px 0;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  text-align: start;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-bottom: 16px;\n  position: relative;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  right: 0;\n  top: 16px;\n\n  div {\n    svg {\n      width: 20px;\n    }\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  text-align: start;\n  font-family: ",
        ";\n  font-weight: 400;\n  align-self: stretch;\n  margin-top: 20px;\n  margin-bottom: 16px;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin: 24px 0;\n    ",
        "\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 0;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  div {\n    border: none;\n    padding: 0;\n  }\n\n  .checkbox-container {\n    margin-bottom: 0;\n\n    & input {\n      margin: 0;\n    }\n\n    @media (min-width: ",
        ") {\n      margin-bottom: 12px;\n    }\n  }\n\n  .title {\n    padding: 10px 0;\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      padding: 4px 0;\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  :first-child {\n    margin-top: 60px;\n    margin-bottom: 16px;\n    height: 45px;\n  }\n\n  height: 58px;\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var CheckDiv = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-0"
})(_templateObject());
export var Container = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-1"
})(_templateObject1(), colors.$262626, breakpoints.tablet, breakpoints.desktop, fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 26), colors.$262626, colors.$e60000);
export var Title = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-2"
})(_templateObject2(), fonts.exbold, pxToRem(32), pxToRem(16), pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), pxToRem(32), breakpoints.tablet, pxToCssFont(20, 30), pxToRem(27.17), breakpoints.desktop, pxToRem(40));
export var DocsDiv = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-4"
})(_templateObject4());
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var Checkbox = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-6"
})(_templateObject6(), colors.$bebebe, colors.$bebebe, colors.$00697c);
export var DocCheck = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-7"
})(_templateObject7(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 26), breakpoints.desktop);
export var Divider = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-8"
})(_templateObject8(), colors.$bebebe, breakpoints.desktop);
export var SubTitle = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-9"
})(_templateObject9(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(24, 30));
export var LoaderIcon = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-10"
})(_templateObject10());
export var SubDescription = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-11"
})(_templateObject11(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var Button = styled.button.withConfig({
    componentId: "sc-5f2e3ed5-12"
})(_templateObject12());
export var CheckboxStyle = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-13"
})(_templateObject13(), breakpoints.desktop, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(20, 30));
export var TextLoader = styled.div.withConfig({
    componentId: "sc-5f2e3ed5-14"
})(_templateObject14(), colors.$ffffff, breakpoints.tablet);
