import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, LoggerInstance } from "@vfit/shared/providers";
import { API, AppMobile, checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useQuery, useQueryClient } from "react-query";
import { errorMock, retrieveUserAgent } from "@vfit/consumer/data-access";
var getActive = function(payload, headers) {
    var ref;
    return awsConsumerClient.get("".concat(API.DXL_ACTIVE_PRODUCT_LIST), {
        searchParams: _object_spread({}, payload),
        headers: _object_spread({}, ((ref = Object.keys(headers)) === null || ref === void 0 ? void 0 : ref.length) > 0 && _object_spread({}, headers))
    });
};
export var useGetActive = function(cusId, deviceId) {
    var enabled = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    var queryClient = useQueryClient();
    return useQuery([
        "getActiveProducts"
    ], function() {
        var isApp = AppMobile.checkIsApp();
        var appSession = isApp && checkWindow() ? getFromLocalStorageByKey("appSession") : null;
        var headers = {
            "X-Device-UserAgent": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent) ? appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent : retrieveUserAgent(),
            "X-Device-Id": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.installationId) ? appSession === null || appSession === void 0 ? void 0 : appSession.installationId : deviceId
        };
        var customerId = isApp ? (appSession === null || appSession === void 0 ? void 0 : appSession.customerId) || cusId : cusId;
        return getActive({
            customerId: customerId
        }, headers);
    }, {
        enabled: enabled,
        onSuccess: function(data) {
            queryClient.setQueryData("getActiveProducts", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - getActiveProducts", error);
            queryClient.setQueryData("getActiveProducts", errorMock("getActiveProducts"));
        }
    });
};
