import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: block;\n\n  .swiper-wrapper {\n    height: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-bottom: 45px;\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 80px;\n  div {\n    margin: 0;\n  }\n  div p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var CardInfoProductContainer = styled.div.withConfig({
    componentId: "sc-879bc2d3-0"
})(_templateObject(), breakpoints.tablet, breakpoints.bigDesktop);
export var CardInfoProductContent = styled.div.withConfig({
    componentId: "sc-879bc2d3-1"
})(_templateObject1());
export var DescriptionContainer = styled.div.withConfig({
    componentId: "sc-879bc2d3-2"
})(_templateObject2());
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-879bc2d3-3"
})(_templateObject3(), fonts.regular, colors.$7e7e7e);
