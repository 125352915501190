import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { checkPanicMode, CMS_CONFIG, CMS_TOOL_START, getDXLProductPrice, isAppMock, ProductType, useCmsConfig, useGetActivable, useGetActive } from "@vfit/consumer/data-access";
import { API, AppMobile, checkWindow, getFromLocalStorageByKey, PAGES } from "@vfit/shared/data-access";
import { DEFAULT_CMS_ID, retrieveDXLBundlePo, retrieveRedirectUrl, showError } from "./retrieveProductDetails.utils";
import { getLandingPrice } from "../utils/price.utils";
import { DEFAULT_MOCK_CATALOG, DEFAULT_MOCK_CATALOG_NBA_ADDON } from "../components/editSession/editSession.utils";
export var useRetrieveProductDetails = function() {
    var ref = useState(true), isLoading = ref[0], setIsLoading = ref[1];
    var ref1 = useState(""), msisdn = ref1[0], setMsisdn = ref1[1];
    var isApp = AppMobile.checkIsApp();
    var globalconfig = (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG) || {}).globalconfig;
    var panicMode = (globalconfig || {}).panicMode;
    var urlParams = new URLSearchParams(window.location.search);
    var queryClient = useQueryClient();
    var productList = queryClient.getQueryData("allProducts");
    var cmsId = (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("cmsid")) || DEFAULT_CMS_ID;
    var defaultProduct = productList === null || productList === void 0 ? void 0 : productList.find(function(p) {
        return p.cmsId === cmsId;
    });
    var isPanicMode = checkPanicMode(panicMode, defaultProduct);
    var ref2 = useState(defaultProduct || null), product = ref2[0], setProduct = ref2[1];
    var isLandingClub = (product === null || product === void 0 ? void 0 : product.isLandingClub) || false;
    var redirectUrl = retrieveRedirectUrl(product);
    var ref3 = useGetActivable(msisdn, ProductType.NBA, ""), activableData = ref3.data, isErrorActivable = ref3.isError, isSuccessActivable = ref3.isSuccess, refetchGetActivable = ref3.refetch;
    var ref4 = useGetActive("", "", false), activeData = ref4.data, isErrorActive = ref4.isError, isSuccessActive = ref4.isSuccess, refetchGetActive = ref4.refetch;
    var checkSession = function() {
        var ref;
        var appSession = isApp && checkWindow() ? getFromLocalStorageByKey("appSession") : null;
        var appItems = (appSession === null || appSession === void 0 ? void 0 : (ref = appSession.items) === null || ref === void 0 ? void 0 : ref.filter(function(el) {
            var ref;
            return (el === null || el === void 0 ? void 0 : (ref = el.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "sim";
        })) || [
            {
                value: (appSession === null || appSession === void 0 ? void 0 : appSession.value) || ""
            }, 
        ];
        var sessionMsisdn = (appItems === null || appItems === void 0 ? void 0 : appItems[0].value) || "";
        if (sessionMsisdn) {
            setMsisdn(sessionMsisdn);
        } else if (product && !sessionMsisdn) {
            var ref1, ref2;
            showError(product === null || product === void 0 ? void 0 : (ref1 = product.pdpSecondAction) === null || ref1 === void 0 ? void 0 : ref1.title, product === null || product === void 0 ? void 0 : (ref2 = product.pdpSecondAction) === null || ref2 === void 0 ? void 0 : ref2.url);
        }
    };
    var onSuccessActivable = function(actData) {
        var ref, ref1;
        if (!product) {
            setIsLoading(false);
            return;
        }
        var allProductsSection = (actData === null || actData === void 0 ? void 0 : (ref = actData.sections) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.products) || [];
        var activableAddon;
        allProductsSection === null || allProductsSection === void 0 ? void 0 : allProductsSection.forEach(function(e) {
            var ref, ref1;
            var allBOIds = (e === null || e === void 0 ? void 0 : (ref = e.campaignDetail) === null || ref === void 0 ? void 0 : (ref1 = ref.components) === null || ref1 === void 0 ? void 0 : ref1.billingOffer) || [];
            if (allBOIds) {
                var ref2;
                var ids = allBOIds === null || allBOIds === void 0 ? void 0 : allBOIds.map(function(aBoId) {
                    return aBoId.id;
                });
                if ((ids === null || ids === void 0 ? void 0 : ids.includes(product === null || product === void 0 ? void 0 : (ref2 = product.offerId) === null || ref2 === void 0 ? void 0 : ref2.toString())) && !activableAddon) {
                    activableAddon = e;
                }
            }
        });
        if (activableAddon) {
            var ref2;
            var ref3 = getLandingPrice(activableAddon, product), price = ref3.price, recurrence = ref3.recurrence, priceVariant = ref3.priceVariant, label = ref3.label, subprice = ref3.subprice;
            setProduct(_object_spread_props(_object_spread({}, product), {
                campaignIdLanding: (activableAddon === null || activableAddon === void 0 ? void 0 : (ref2 = activableAddon.id) === null || ref2 === void 0 ? void 0 : ref2.campaignId) || "",
                isLoaded: true,
                price: (price === null || price === void 0 ? void 0 : price.toString()) || "",
                recurrence: recurrence,
                priceVariant: priceVariant,
                label: label,
                subprice: subprice
            }));
        } else {
            var ref4;
            setProduct(_object_spread_props(_object_spread({}, product), {
                isLoaded: true,
                price: (product === null || product === void 0 ? void 0 : (ref4 = product.price) === null || ref4 === void 0 ? void 0 : ref4.includes("$PRICE")) ? "" : (product === null || product === void 0 ? void 0 : product.price) || "",
                action: {
                    type: 4,
                    callMeNow: product === null || product === void 0 ? void 0 : product.landingCTC
                }
            }));
        }
        setIsLoading(false);
    };
    var onSuccessActive = function(actData) {
        if (!product) {
            setIsLoading(false);
            return;
        }
        var dxlBundlePO = retrieveDXLBundlePo(actData || null);
        if (product && dxlBundlePO) {
            var ref, ref1, ref2;
            setProduct(_object_spread_props(_object_spread({}, product), {
                isLoaded: true,
                price: getDXLProductPrice(dxlBundlePO) || (product === null || product === void 0 ? void 0 : product.price) || "",
                recurrence: ((ref = dxlBundlePO) === null || ref === void 0 ? void 0 : (ref1 = ref.cmsCustomization) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.renewalCost) === null || ref2 === void 0 ? void 0 : ref2.frequency) || (product === null || product === void 0 ? void 0 : product.recurrence) || ""
            }));
            setIsLoading(false);
        }
    };
    useEffect(function() {
        if (isErrorActivable) {
            var ref, ref1;
            showError(product === null || product === void 0 ? void 0 : (ref = product.pdpSecondAction) === null || ref === void 0 ? void 0 : ref.title, product === null || product === void 0 ? void 0 : (ref1 = product.pdpSecondAction) === null || ref1 === void 0 ? void 0 : ref1.url);
        } else if (!isAppMock() && isSuccessActivable && activableData && productList) {
            onSuccessActivable(activableData);
        }
    }, [
        isSuccessActivable,
        isErrorActivable,
        activableData,
        productList
    ]);
    useEffect(function() {
        if (isErrorActive) {
            var ref, ref1;
            showError(product === null || product === void 0 ? void 0 : (ref = product.pdpSecondAction) === null || ref === void 0 ? void 0 : ref.title, product === null || product === void 0 ? void 0 : (ref1 = product.pdpSecondAction) === null || ref1 === void 0 ? void 0 : ref1.url);
        } else if (!isAppMock() && isSuccessActive && activeData && productList) {
            onSuccessActive(activeData);
        }
    }, [
        isSuccessActive,
        isErrorActive,
        activeData,
        productList
    ]);
    useEffect(function() {
        if (msisdn && !isSuccessActivable) {
            if (!isAppMock()) {
                if (isLandingClub) refetchGetActivable();
                else refetchGetActive();
            } else if (isLandingClub) {
                // simulate loader
                setTimeout(function() {
                    var dxlCatalogResponseMock = localStorage.getItem("dxlCatalogResponse") || JSON.stringify(DEFAULT_MOCK_CATALOG_NBA_ADDON);
                    onSuccessActivable(JSON.parse(dxlCatalogResponseMock));
                }, 2000);
            } else {
                setTimeout(function() {
                    var dxlCatalogResponseMock = localStorage.getItem("dxlCatalogResponse") || JSON.stringify(DEFAULT_MOCK_CATALOG);
                    onSuccessActive(JSON.parse(dxlCatalogResponseMock));
                }, 2000);
            }
        }
    }, [
        msisdn
    ]);
    useEffect(function() {
        checkSession();
        localStorage.setItem(CMS_TOOL_START, cmsId);
    }, []);
    return {
        product: product,
        serviceData: activableData,
        isError: isErrorActivable,
        isLoading: isLoading,
        redirectUrl: redirectUrl,
        isPanicMode: isPanicMode,
        isLandingClub: isLandingClub
    };
};
