import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 18px;\n  align-items: center;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  max-width: 80%;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-weight: ",
        ";\n  color: ",
        ";\n  font-size: 18px;\n  margin: 0;\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-size: 18px;\n  margin: 0;\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 32px;\n  height: 32px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var ListContainer = styled.div.withConfig({
    componentId: "sc-cad3558c-0"
})(_templateObject());
export var ListElement = styled.div.withConfig({
    componentId: "sc-cad3558c-1"
})(_templateObject1());
export var TextContainer = styled.div.withConfig({
    componentId: "sc-cad3558c-2"
})(_templateObject2());
export var Text = styled.p.withConfig({
    componentId: "sc-cad3558c-3"
})(_templateObject3(), function(props) {
    return props.fontWeight ? props.fontWeight : "700";
}, function(props) {
    return props.color ? props.color : "white";
});
export var SubText = styled.p.withConfig({
    componentId: "sc-cad3558c-4"
})(_templateObject4(), function(props) {
    return props.color ? props.color : "white";
});
export var IconContainer = styled.div.withConfig({
    componentId: "sc-cad3558c-5"
})(_templateObject5());
