import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { START_FROM_APP_LOCKIN, DXL_NBO_CAMPAIGNS } from "@vfit/consumer/data-access";
import { handleUseQuery, LoggerInstance, awsConsumerClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method calls che campaigns service
 * @param payload
 * @param headers
 */ var getActivableProduct = function(payload, headers) {
    var ref;
    return awsConsumerClient.get("".concat(API.DXL_ACTIVE_PRODUCT_LIST), {
        searchParams: _object_spread({}, payload),
        headers: _object_spread({}, ((ref = Object.keys(headers)) === null || ref === void 0 ? void 0 : ref.length) > 0 && _object_spread({}, headers))
    });
};
export var setDxlCampaigns = function(dxlCampaignsProducts) {
    var dxlNboCampaignsFixed = dxlCampaignsProducts === null || dxlCampaignsProducts === void 0 ? void 0 : dxlCampaignsProducts.filter(function(campaign) {
        var ref;
        return campaign === null || campaign === void 0 ? void 0 : (ref = campaign.type) === null || ref === void 0 ? void 0 : ref.includes("fixed");
    });
    if (dxlNboCampaignsFixed && (dxlNboCampaignsFixed === null || dxlNboCampaignsFixed === void 0 ? void 0 : dxlNboCampaignsFixed.length) > 0) {
        localStorage.setItem(START_FROM_APP_LOCKIN, "true");
        localStorage.setItem(DXL_NBO_CAMPAIGNS, JSON.stringify(dxlNboCampaignsFixed));
        return dxlNboCampaignsFixed;
    }
    return [];
};
/**
 * This method recalls the customer ID and use it to returns all the customer information saved
 *
 */ export var useDXLCampaigns = function(customOptions) {
    LoggerInstance.debug("********* useDXLCampaigns");
    var queryEnabler = false;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : [
        "dxlCampaigns"
    ];
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var appSession = getFromLocalStorageByKey("appSession");
            var customerId = (appSession === null || appSession === void 0 ? void 0 : appSession.customerId) || "";
            var customerUserAgent = (appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent) || "";
            var installationId = (appSession === null || appSession === void 0 ? void 0 : appSession.installationId) || "";
            var token = (appSession === null || appSession === void 0 ? void 0 : appSession.token) || "";
            var customHeaders = {
                "X-Device-UserAgent": customerUserAgent,
                "X-Device-Id": installationId,
                "vf-a-appconsumerauth": token
            };
            return getActivableProduct({
                customerId: customerId
            }, customHeaders);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler,
            onSuccess: function(data) {
                setDxlCampaigns(data);
                LoggerInstance.debug("useDXLCampaigns onSuccess : ", data);
            },
            onError: function(error) {
                LoggerInstance.debug("UseDxlCampaigns", error);
            }
        })
    };
    return handleUseQuery(options);
};
