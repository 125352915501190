import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { IOfferCategorizationType } from "./simTypeSelection.models";
export var organizeSimTypeCard = function(dataFromCms, errorPortability) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32;
    return {
        cardTitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.simType) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.card) === null || ref2 === void 0 ? void 0 : ref2.title) || "eSim",
        cardDescription: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.personalinfomobile) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.simType) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.card) === null || ref5 === void 0 ? void 0 : ref5.description) || "",
        modalInfo: {
            textLink: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.simType) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.modalInfo) === null || ref8 === void 0 ? void 0 : ref8.textLink) || "",
            titleModal: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.personalinfomobile) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.simType) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.modalInfo) === null || ref11 === void 0 ? void 0 : ref11.title) || "",
            descriptionModal: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref12 = dataFromCms.personalinfomobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.simType) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.modalInfo) === null || ref14 === void 0 ? void 0 : ref14.description) || ""
        },
        migrationCodeError: {
            title: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref15 = errorPortability.portabilitymobile) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.errorOnPortability) === null || ref16 === void 0 ? void 0 : ref16.title) || "Ops",
            description: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref17 = errorPortability.portabilitymobile) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.errorOnPortability) === null || ref18 === void 0 ? void 0 : ref18.message) || "Il numero non pu\xf2 essere portato in Vodafone",
            buttonLabel: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref19 = errorPortability.portabilitymobile) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.errorOnPortability) === null || ref20 === void 0 ? void 0 : ref20.buttonLabel) || "Riprova"
        },
        genericsError: {
            title: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref21 = errorPortability.portabilitymobile) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.errorModal) === null || ref22 === void 0 ? void 0 : ref22.title) || "Ops",
            description: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref23 = errorPortability.portabilitymobile) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.errorModal) === null || ref24 === void 0 ? void 0 : ref24.message) || "Si \xe8 verificato un errore.",
            buttonLabel: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref25 = errorPortability.portabilitymobile) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.errorModal) === null || ref26 === void 0 ? void 0 : ref26.buttonLabel) || "Riprova"
        },
        numberError: {
            title: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref27 = errorPortability.portabilitymobile) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.errorNumber) === null || ref28 === void 0 ? void 0 : ref28.title) || "Ops",
            description: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref29 = errorPortability.portabilitymobile) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.errorNumber) === null || ref30 === void 0 ? void 0 : ref30.message) || "Numero errato per la portabilit\xe0",
            buttonLabel: (errorPortability === null || errorPortability === void 0 ? void 0 : (ref31 = errorPortability.portabilitymobile) === null || ref31 === void 0 ? void 0 : (ref32 = ref31.errorNumber) === null || ref32 === void 0 ? void 0 : ref32.buttonLabel) || "Riprova"
        }
    };
};
export var purifyPrice = function(price) {
    return parseFloat(price.replace(",", ".").replace("€", ""));
};
export var comparePrice = function(firstProduct, secondProduct) {
    return purifyPrice(firstProduct.price) - purifyPrice(secondProduct.price);
};
export var alreadyDonePortability = function() {
    var ref;
    var checkPortInFeasibility = getFromLocalStorageByKey("checkPortInFeasibilityIIQuery");
    return (checkPortInFeasibility === null || checkPortInFeasibility === void 0 ? void 0 : (ref = checkPortInFeasibility.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "success" || checkPortInFeasibility && Object.keys(checkPortInFeasibility).length === 0;
};
export var checkOperator = function(chosenOperator, groups, avaiableOperators) {
    var ref, ref1;
    var isChosenOperatorAvaiable = false;
    if ((avaiableOperators === null || avaiableOperators === void 0 ? void 0 : (ref = avaiableOperators.avaiableOperators) === null || ref === void 0 ? void 0 : ref.find(function(operator) {
        var ref;
        return ((ref = operator.elements) === null || ref === void 0 ? void 0 : ref.trim()) === chosenOperator;
    })) !== undefined) isChosenOperatorAvaiable = true;
    avaiableOperators === null || avaiableOperators === void 0 ? void 0 : (ref1 = avaiableOperators.avaiableGroups) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(avaiableGropus) {
        var ref;
        var group = groups === null || groups === void 0 ? void 0 : groups.find(function(groupFromProps) {
            return groupFromProps.id === avaiableGropus.elements;
        });
        if ((group === null || group === void 0 ? void 0 : (ref = group.operator) === null || ref === void 0 ? void 0 : ref.find(function(operator) {
            var ref;
            return (operator === null || operator === void 0 ? void 0 : (ref = operator.operators) === null || ref === void 0 ? void 0 : ref.trim()) === chosenOperator;
        })) !== undefined) isChosenOperatorAvaiable = true;
    });
    return isChosenOperatorAvaiable;
};
export var checkProductAvaiable = function(product, chosenOperator, productList, groups) {
    var filteredProducts = productList === null || productList === void 0 ? void 0 : productList.filter(function(element) {
        return element.productCategory === product.productCategory;
    });
    var avaiableFallbackProducts = [];
    switch(product.offerCategorization){
        case IOfferCategorizationType.BTL:
            avaiableFallbackProducts = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.filter(function(filteredProduct) {
                return checkOperator(chosenOperator, groups, filteredProduct.offerAvaiability);
            });
            break;
        case IOfferCategorizationType.ATL:
            if (product.fallbackToBtl) avaiableFallbackProducts = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.filter(function(filteredProduct) {
                return checkOperator(chosenOperator, groups, filteredProduct.offerAvaiability);
            });
            else {
                avaiableFallbackProducts = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.filter(function(categorizedProduct) {
                    return categorizedProduct.offerCategorization === IOfferCategorizationType.ATL;
                }).filter(function(filteredProduct) {
                    return checkOperator(chosenOperator, groups, filteredProduct.offerAvaiability);
                });
            }
            break;
        default:
            break;
    }
    if (avaiableFallbackProducts && avaiableFallbackProducts.length > 0) {
        if (avaiableFallbackProducts.length === 1) return avaiableFallbackProducts[0];
        return avaiableFallbackProducts.sort(comparePrice)[0];
    }
    return undefined;
};
export var findOperatorValue = function(chosenOperator) {
    var portabilityInfo = getFromLocalStorageByKey("portabilityInfo");
    if (portabilityInfo !== undefined && Object.keys(portabilityInfo).length > 0) {
        var ref, ref1, ref2, ref3;
        var operatorsList = (portabilityInfo === null || portabilityInfo === void 0 ? void 0 : (ref = portabilityInfo.portInDetails) === null || ref === void 0 ? void 0 : ref.mobilePortInDetails) && ((ref2 = portabilityInfo === null || portabilityInfo === void 0 ? void 0 : (ref1 = portabilityInfo.portInDetails) === null || ref1 === void 0 ? void 0 : ref1.mobilePortInDetails[0]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.portInProviderCust) === null || ref3 === void 0 ? void 0 : ref3.availableValues) || [];
        var foundOperator = operatorsList.find(function(operator) {
            return operator.value === chosenOperator;
        });
        return foundOperator === null || foundOperator === void 0 ? void 0 : foundOperator.displayValue;
    }
    return undefined;
};
export var organizeNewProductData = function(product, chosenOperator) {
    var offerDetails = product.offerDetails, title = product.title, price = product.price, conditions = product.conditions, description = product.description, recurrence = product.recurrence, specs = product.specs, digitalBundleConfig = product.digitalBundleConfig, fallbackCtcEnabled = product.fallbackCtcEnabled;
    var offerData = {
        price: price || "",
        recurrence: recurrence || "",
        cTCEnabled: fallbackCtcEnabled || false,
        offerDetails: {
            conditions: conditions || [],
            offerDetails: offerDetails || [],
            disableSecondAction: (digitalBundleConfig === null || digitalBundleConfig === void 0 ? void 0 : digitalBundleConfig.disableSecondAction) || false,
            title: title
        },
        priceDescription: description || "",
        offerProposition: {
            title: (digitalBundleConfig === null || digitalBundleConfig === void 0 ? void 0 : digitalBundleConfig.title.replace("$OPERATOR", findOperatorValue(chosenOperator) || "")) || "",
            description: (digitalBundleConfig === null || digitalBundleConfig === void 0 ? void 0 : digitalBundleConfig.description) || "",
            showDetailLabel: (digitalBundleConfig === null || digitalBundleConfig === void 0 ? void 0 : digitalBundleConfig.showDetailsLabel) || ""
        },
        specs: specs || [],
        firstButtonAction: (digitalBundleConfig === null || digitalBundleConfig === void 0 ? void 0 : digitalBundleConfig.firstAction) || {},
        secondButtonAction: (digitalBundleConfig === null || digitalBundleConfig === void 0 ? void 0 : digitalBundleConfig.secondAction) || {}
    };
    return offerData;
};
export var organizeNoOfferPopUp = function(dataFromCms, chosenOperator) {
    var ref;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.title) || "",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.description) === null || ref === void 0 ? void 0 : ref.replace("$OPERATOR", findOperatorValue(chosenOperator) || "")) || "",
        buttonLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.buttonLabel) || "",
        url: dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.url
    };
};
