import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, LoggerInstance } from "@vfit/shared/providers";
import { API, AppMobile, checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useMutation } from "react-query";
import { retrieveUserAgent } from "../../../utils/app";
var postActivation = function(payload, msisdn, productType, headers) {
    var ref;
    return awsConsumerClient.post("".concat(API.DXL_ACTIVATE_PRODUCTS), payload, {
        headers: _object_spread({}, ((ref = Object.keys(headers)) === null || ref === void 0 ? void 0 : ref.length) > 0 && _object_spread({}, headers)),
        searchParams: {
            sim: msisdn,
            productType: productType
        }
    });
};
export var usePostActivation = function() {
    return useMutation("activateProduct", function(request) {
        var isApp = AppMobile.checkIsApp();
        var appSession = isApp && checkWindow() ? getFromLocalStorageByKey("appSession") : null;
        var headers = {
            "X-Device-UserAgent": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent) ? appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent : retrieveUserAgent(),
            "X-Device-Id": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.installationId) ? appSession === null || appSession === void 0 ? void 0 : appSession.installationId : request.deviceId
        };
        return postActivation(request.payload, request.queryString.msisdn, request.queryString.productType, headers);
    }, {
        onSuccess: function(data) {
            LoggerInstance.error("SUCCESS - activateProducts", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - activateProducts", error);
        }
    });
};
